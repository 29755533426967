<template>
    <v-menu dense>
        <template v-slot:activator="d">
            <slot name="activator" v-bind="{ on: d.on, attrs: d.attrs, select: select, itemText: itemText, textFilter: textFilter }">
                <v-btn
                    :class="buttonClass"
                    :disabled="disabled"
                    :small="small"
                    icon
                    v-bind="d.attrs"
                    v-on="d.on"
                    :title="nestVal(select, itemText, textFilter)">
                    <v-icon :small="small" :class="select != null ? 'success--text' : ''">{{ icon }}</v-icon>
                </v-btn>
            </slot>
        </template>

        <v-list>
            <v-list-item-group
                v-model="select"
                @change="update">
                <template v-for="(f, i) in filteredItems">
                    <v-list-item :key="i" :value="f">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ nestVal(f, itemText, textFilter) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Menu-Select',
    data: function() {
        return {
            asyncItems: [],
            select: null,
            errorMessage: null,
            isLoading: false,
            showError: false,
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        canSelectNone: {
            type: Boolean,
            default: true
        },
        chips: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        hideRefresh: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-timer'
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        outlined: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        prefix: {
            type: String,
            default: null
        },
        prependIcon: {
            type: String,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        returnCSV: {
            type: Boolean,
            default: false
        },
        returnIndex: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        },
        suffix: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
        value: null, 
    },
    async mounted() {
        await this.pullItems();

        if (this.returnCSV) {
            this.select = this.value ? this.value.split(',') : null;
        }
        else if (this.itemValue != null) {
            this.select = this.asyncItems.find(x => x[this.itemValue] == this.value);
        }
        else {
            this.select = this.value;
        }
    },
    watch: {
        value: function(val) {
            console.log('v changed');
            if (val != this.select) {
                console.log('val changed');
                if (this.returnCSV) {
                    this.select = val ? val.split(',') : null;
                }
                else if (this.itemValue != null) {
                    this.select = this.asyncItems.find(x => x[this.itemValue] == val);
                }
                else {
                    this.select = val;
                }
            }
        },
        items: function() {
            this.pullItems();
        },
        proxyID() {
            this.pullItems();
        }
    },
    computed: {
        filteredItems() {
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
        },
        showRefreshButton() {
            return !this.hideRefresh && !this.isLengthyArray(this.asyncItems) && this.navigation != null; //make sure it is navigation based
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.isLoading = true;
                this.$forceUpdate();

                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh, this.proxyID);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh, this.proxyID);
                }
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        update() {
            if (this.select == null) {
                this.$emit('input', null)
                this.$emit('change', null);
            }
            else if (this.returnCSV) {
                if (this.itemValue != null) {
                    this.$emit('input', this.select.map(x => x[this.itemValue]).toString())
                    this.$emit('change', this.select.map(x => x[this.itemValue]).toString());
                }
                else {
                    this.$emit('input', this.select.toString())
                    this.$emit('change', this.select.toString());
                }
            }
            else if (this.itemValue != null && typeof(this.select) == 'object') {
                this.$emit('input', this.select[this.itemValue]);
                this.$emit('change', this.select[this.itemValue]);
            }
            else if (this.returnIndex) {
                this.$emit('input', this.items.findIndex(x => x == this.select));
                this.$emit('change', this.items.findIndex(x => x == this.select));
            }
            else {
                this.$emit('input', this.select)
                this.$emit('change', this.select);
            }
        }
    }
}
</script>